import React from "react"
import SeoHeaders from "../components/seo-headers/seo-headers"
import LayoutMain from "../components/layout-main/layout-main"
import HeroUnit from "../components/hero-unit/hero-unit"

import "./home.scss"

const PortfolioPage = () => (
  <LayoutMain>
    <SeoHeaders title="Portfolio" keywords={[`portfolio`]} />
    <HeroUnit pageTitle="Portfolio" />
  </LayoutMain>
)

export default PortfolioPage
